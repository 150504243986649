import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PatientForm from './PatientForm';
import { 
  fetchPatients, 
  deletePatient, 
  updatePatient, 
  createPatient 
} from '../../../services/patientService';
import axios from 'axios';
import { makeApiRequest } from '../../../utils/apiUtils';
import { api } from '../../../services/patientService';

const Container = styled.div`
  padding: 24px;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-shrink: 0;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #1d1d1f;
`;

const AddButton = styled.button`
  padding: 8px 16px;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover {
    background: #0056b3;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  
  thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    
    th {
      border-bottom: 2px solid #e1e1e1;
    }
  }
`;

const ScrollContainer = styled.div`
  flex: 1;
  overflow: auto;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Th = styled.th`
  padding: 6px 4px;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
  color: #86868b;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  font-size: 11px;
  font-weight: 600;
`;

const Td = styled.td`
  padding: 4px;
  border-bottom: 1px solid #e1e1e1;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
`;

const TooltipTd = styled(Td)`
  position: relative;
  
  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: normal;
    max-width: 300px;
    z-index: 2;
    word-break: break-all;
  }
`;

const ActionButton = styled.button`
  padding: 2px 4px;
  margin-right: 2px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 11px;
  
  &.edit {
    background: #e3f2fd;
    color: #1976d2;
  }
  
  &.delete {
    background: #ffebee;
    color: #d32f2f;
  }
`;

const RoleFilter = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  flex-shrink: 0;
`;

const FilterButton = styled.button`
  padding: 4px 8px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background: ${props => props.active ? '#007AFF' : 'white'};
  color: ${props => props.active ? 'white' : '#1d1d1f'};
  cursor: pointer;
  
  &:hover {
    background: ${props => props.active ? '#0056b3' : '#f5f5f7'};
  }
`;

// 역할 상수 정의 추가
const ROLES = {
  ALL: 'all',
  ADMIN: 'admin',
  OPERATOR: 'operator',
  PATIENT: 'patient',
  MEMBER: 'member'
};

// 언어 상수 정의 (중복 제거)
const LANGUAGES = [
  { code: 'ko', name: '한국어' },
  { code: 'en', name: '영어' },
  { code: 'zh', name: '중국어' },
  { code: 'ja', name: '일본어' },
  { code: 'es', name: '스페인어' },
  { code: 'fr', name: '프랑스어' },
  { code: 'de', name: '독일어' },
  { code: 'it', name: '이탈리아어' },
  { code: 'pt', name: '포르투갈어' },
  { code: 'ru', name: '러시아어' },
  { code: 'ar', name: '아랍어' },
  { code: 'hi', name: '힌디어' },
  { code: 'bn', name: '벵골어' },
  { code: 'pa', name: '펀지아어' },
  { code: 'sw', name: '스와힐리어' },
  { code: 'ta', name: '타밀어' },
  { code: 'te', name: '텔루구어' },
  { code: 'th', name: '태국어' },
  { code: 'tr', name: '터키어' },
  { code: 'ur', name: '우르두어' },
  { code: 'vi', name: '베트남어' },
  { code: 'id', name: '인도네시아어' },
  { code: 'ms', name: '말레이어' }
];

// 날짜 포맷팅 함수 추가
const formatDateTime = (dateString) => {
  if (!dateString) return '-';
  return new Date(dateString).toLocaleString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
};

// 국가 번호 포맷팅 함수
const formatPhoneCode = (code) => {
  if (!code) return '';
  return code.startsWith('+') ? code : `+${code}`;
};

// 날짜 포맷팅 함수 추가
const formatBirthDate = (dateString) => {
  if (!dateString) return '-';
  return new Date(dateString).toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\. /g, '-').replace('.', '');
};

// API 기본 URL 설정
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://106.248.231.106:3000';

// 인라인 편집을 위한 새로운 스타일 컴포넌트 추가
const EditableCell = styled(Td)`
  padding: 4px;
  position: relative;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
    box-shadow: inset 0 0 0 1px #007AFF;
    border-radius: 2px;
  }

  &.editing {
    background-color: #fff;
    box-shadow: inset 0 0 0 2px #007AFF;
    border-radius: 2px;
    padding: 0;
  }

  &:hover .edit-icon {
    opacity: 1;
  }
`;

const EditInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 4px;
  border: none;
  background: transparent;
  font-size: 11px;
  border-radius: 2px;
  
  &:focus {
    outline: none;
    background: white;
  }
`;

const EditSelect = styled.select`
  width: 100%;
  height: 100%;
  padding: 4px;
  border: none;
  background: transparent;
  font-size: 11px;
  border-radius: 2px;
  cursor: pointer;
  
  &:focus {
    outline: none;
    background: white;
  }

  option {
    background: white;
    color: #1d1d1f;
  }
`;

const EditIcon = styled.span`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  color: #007AFF;
  font-size: 12px;
  transition: opacity 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  padding: 2px;
  border-radius: 4px;
`;

// 환자 상태 뱃지 스타일 추가
const PatientStatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  
  ${props => {
    switch (props.status) {
      case 'waiting':
        return 'background: #FFF3E0; color: #E65100; border: 1px solid #E65100;';
      case 'reserved':
        return 'background: #E3F2FD; color: #1565C0; border: 1px solid #1565C0;';
      case 'outpatient':
        return 'background: #E8F5E9; color: #2E7D32; border: 1px solid #2E7D32;';
      case 'hospitalized':
        return 'background: #EDE7F6; color: #4527A0; border: 1px solid #4527A0;';
      case 'discharged':
        return 'background: #EFEBE9; color: #4E342E; border: 1px solid #4E342E;';
      default:
        return 'background: #F5F5F5; color: #424242; border: 1px solid #9E9E9E;';
    }
  }}
`;

// 환자 상태 라벨 변환 함수를 최상위로 이동
const getPatientStatusLabel = (status) => {
  switch (status) {
    case 'waiting': return '대기';
    case 'reserved': return '예약';
    case 'outpatient': return '외래';
    case 'hospitalized': return '입원';
    case 'discharged': return '귀가';
    default: return '미정';
  }
};

const isPatientStatus = (value) => {
  const patientStatuses = ['waiting', 'reserved', 'outpatient', 'hospitalized', 'discharged'];
  return value && patientStatuses.includes(value);
};

// 수정 가능한 셀 컴포넌트
const EditableField = ({ value, onChange, type = 'text', options = [], disabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleDoubleClick = () => {
    if (!disabled) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (tempValue !== value) {
      onChange(tempValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    } else if (e.key === 'Escape') {
      setTempValue(value);
      setIsEditing(false);
    }
  };

  const renderValue = () => {
    if (type === 'select' && isPatientStatus(value)) {
      return (
        <PatientStatusBadge status={value}>
          {patientStatusMap[value] || '대기'}
        </PatientStatusBadge>
      );
    }
    return <span style={{ padding: '8px', display: 'block' }}>{value || '-'}</span>;
  };

  return (
    <EditableCell 
      onDoubleClick={handleDoubleClick}
      className={isEditing ? 'editing' : ''}
      style={disabled ? { cursor: 'not-allowed' } : {}}
    >
      {isEditing ? (
        type === 'select' ? (
          <EditSelect
            ref={inputRef}
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </EditSelect>
        ) : (
          <EditInput
            ref={inputRef}
            type={type}
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        )
      ) : (
        <>
          {renderValue()}
          {!disabled && <EditIcon className="edit-icon">✎</EditIcon>}
        </>
      )}
    </EditableCell>
  );
};

// 검색 입력 필드를 위한 스타일 컴포넌트 추가
const SearchContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  flex-shrink: 0;
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  width: 300px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const SearchSelect = styled.select`
  padding: 8px 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

// 환자 상태 매핑 객체 추가
const patientStatusMap = {
  'waiting': '대기',
  'reserved': '예약',
  'outpatient': '외래',
  'hospitalized': '입원',
  'discharged': '귀가'
};

// 환자 상태 옵션 배열
const patientStatusOptions = [
  { value: 'waiting', label: '대기' },
  { value: 'reserved', label: '예약' },
  { value: 'outpatient', label: '외래' },
  { value: 'hospitalized', label: '입원' },
  { value: 'discharged', label: '귀가' }
];

const PatientList = () => {
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedRole, setSelectedRole] = useState(ROLES.ALL);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [loading, setLoading] = useState(false);

  const fetchPatientList = async () => {
    try {
      setLoading(true);
      const data = await fetchPatients();
      if (data.success) {
        setPatients(data.data || []);
      } else {
        toast.error('환자 목록을 불러오는데 실패했습니다.');
      }
    } catch (error) {
      toast.error('환자 목록을 불러오는데 실패했습니다.');
      console.error('환자 목록 조회 실패:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPatientList();
  }, []);

  // 검색 필드 옵션 수정
  const searchFields = [
    { value: 'name', label: '이름' },
    { value: 'english_name', label: '영문 이름' },
    { value: 'local_name', label: '현지어 이름' },
    { value: 'passport_number', label: '여권번호' },
    { value: 'patient_number', label: '환자번호' },
    { value: 'hospital_patient_no', label: '병원환자번호' },
    { value: 'phone_number', label: '전화번호' },
    { value: 'email', label: '이메일' }
  ];

  // 검색 핸들러 수정
  const handleSearchFieldChange = (e) => {
    setSearchField(e.target.value);
    setSearchTerm('');
  };

  // 필터링 로직 수정
  const filteredPatients = patients.filter(patient => {
    // 검색어 필터링
    if (searchTerm) {
      const searchValue = searchTerm.toLowerCase().trim();
      const fieldValue = patient[searchField];

      // fieldValue가 null 또는 undefined인 경우 빈 문자열로 처리
      const valueToSearch = fieldValue ? fieldValue.toString().toLowerCase() : '';

      // 검색어가 포함되어 있는지 확인
      if (!valueToSearch.includes(searchValue)) {
        return false;
      }
    }

    // 역할 필터링
    switch (selectedRole) {
      case ROLES.ALL:
        return true;
      case ROLES.ADMIN:
        return patient.role_id === 1;
      case ROLES.OPERATOR:
        return patient.role_id === 2;
      case ROLES.PATIENT:
        return patient.role_id === 3;
      case ROLES.MEMBER:
        return patient.role_id === 4;
      default:
        return true;
    }
  });

  // 검색 핸들러
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // 역할 이름 변환 함수
  const getRoleName = (roleId) => {
    if (roleId === 1) return '관리자';
    if (roleId === 2) return '운영자';
    if (roleId === 3) return '환자';
    if (roleId === 4) return '가입자';
    return '미분류';
  };

  const handleEdit = (patient) => {
    setSelectedPatient(patient);
    setShowForm(true);
  };

  const handleDelete = async (userId) => {
    if (window.confirm('정말로 이 환자를 삭제하시겠습니까?')) {
      try {
        await deletePatient(userId);
        toast.success('환자가 성공적으로 삭제되었습니다.');
        await fetchPatientList();
      } catch (error) {
        toast.error(error.message || '환자 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleFormSubmit = async (formData) => {
    try {
      if (selectedPatient) {
        // 기존 환자 수정
        const result = await updatePatient(selectedPatient.userId, formData);
        if (result.success) {
          toast.success('환자 정보가 수정되었습니다.');
          await fetchPatientList();
        } else {
          throw new Error(result.message || '환자 정보 수정에 실패했습니다.');
        }
      } else {
        // 새 환자 등록
        const response = await createPatient({
          ...formData,
          role_id: 3, // 환자 역할 ID
          status: 'active'
        });
        
        if (response.success) {
          toast.success('새 환자가 등록되었습니다.');
          await fetchPatientList();
        } else {
          throw new Error(response.message || '환자 등록에 실패했습니다.');
        }
      }
      setShowForm(false);
      setSelectedPatient(null);
    } catch (error) {
      toast.error(error.message);
      console.error('환자 정보 처리 실패:', error);
    }
  };

  const handleFieldUpdate = async (userId, field, value) => {
    try {
      // 필드 매핑
      const fieldMapping = {
        patientStatus: 'patient_status'
      };

      // 업데이트할 데이터 준비
      const serverField = fieldMapping[field] || field;
      const updateData = {
        [serverField]: value
      };

      // patient_status를 업데이트할 때는 status도 함께 업데이트
      if (serverField === 'patient_status') {
        updateData.status = 'active';
      }

      console.log('Updating user:', userId, 'with data:', updateData);

      const response = await makeApiRequest(`/api/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: updateData
      });

      if (response.data.success) {
        toast.success('정보가 업데이트되었습니다.');
        await fetchPatientList(); // 목록 새로고침
      } else {
        throw new Error(response.data.message || '업데이트 실패');
      }
    } catch (error) {
      console.error('Update failed:', error);
      toast.error('업데이트 실패: ' + error.message);
    }
  };

  return (
    <Container>
      <Header>
        <Title>환자 관리</Title>
        <AddButton onClick={() => setShowForm(true)}>
          새 환자 등록
        </AddButton>
      </Header>

      <SearchContainer>
        <SearchSelect 
          value={searchField}
          onChange={handleSearchFieldChange}
        >
          {searchFields.map(field => (
            <option key={field.value} value={field.value}>
              {field.label}
            </option>
          ))}
        </SearchSelect>
        <SearchInput
          type="text"
          placeholder={`${searchFields.find(f => f.value === searchField)?.label}으로 검색`}
          value={searchTerm}
          onChange={handleSearch}
        />
      </SearchContainer>

      <RoleFilter>
        <FilterButton 
          active={selectedRole === ROLES.ALL}
          onClick={() => setSelectedRole(ROLES.ALL)}
        >
          전체 보기
        </FilterButton>
        <FilterButton 
          active={selectedRole === ROLES.ADMIN}
          onClick={() => setSelectedRole(ROLES.ADMIN)}
        >
          관리자
        </FilterButton>
        <FilterButton 
          active={selectedRole === ROLES.OPERATOR}
          onClick={() => setSelectedRole(ROLES.OPERATOR)}
        >
          운영자
        </FilterButton>
        <FilterButton 
          active={selectedRole === ROLES.PATIENT}
          onClick={() => setSelectedRole(ROLES.PATIENT)}
        >
          환자
        </FilterButton>
        <FilterButton 
          active={selectedRole === ROLES.MEMBER}
          onClick={() => setSelectedRole(ROLES.MEMBER)}
        >
          가입자
        </FilterButton>
      </RoleFilter>

      <ScrollContainer>
        {loading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>로딩 중...</div>
        ) : (
          <Table>
            <thead>
              <tr>
                <Th>이름</Th>
                <Th>영문이름</Th>
                <Th>현지어이름</Th>
                <Th>이메일</Th>
                <Th>성별</Th>
                <Th>생년월일</Th>
                <Th>국적</Th>
                <Th>여권번호</Th>
                <Th>환자번호</Th>
                <Th>병원환자번호</Th>
                <Th>전화번호</Th>
                <Th>국내주소</Th>
                <Th>선호 언어</Th>
                <Th>구분</Th>
                <Th>기저질환</Th>
                <Th>복용약물</Th>
                <Th>알레르기</Th>
                <Th>보험가입</Th>
                <Th>보험상세</Th>
                <Th>가입일</Th>
                <Th>최근 로그인</Th>
                <Th>환자상태</Th>
                <Th>작업</Th>
              </tr>
            </thead>
            <tbody>
              {filteredPatients.map(patient => (
                <tr key={patient.userId}>
                  <EditableField
                    value={patient.name}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'name', value)}
                  />
                  <EditableField
                    value={patient.english_name}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'english_name', value)}
                  />
                  <EditableField
                    value={patient.local_name}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'local_name', value)}
                  />
                  <EditableField
                    value={patient.email}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'email', value)}
                    disabled={true}  // 이메일은 수정 불가
                  />
                  <EditableField
                    value={patient.gender}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'gender', value)}
                    type="select"
                    options={[
                      { value: 'M', label: '남' },
                      { value: 'F', label: '여' },
                      { value: 'O', label: '기타' }
                    ]}
                  />
                  <Td>{formatBirthDate(patient.birth_date)}</Td>
                  <EditableField
                    value={patient.nationality}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'nationality', value)}
                  />
                  <EditableField
                    value={patient.passport_number}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'passport_number', value)}
                  />
                  <EditableField
                    value={patient.patient_number}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'patient_number', value)}
                  />
                  <EditableField
                    value={patient.hospital_patient_no}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'hospital_patient_no', value)}
                  />
                  <EditableField
                    value={`${formatPhoneCode(patient.phone_code)} ${patient.phone_number}`}
                    onChange={(value) => {
                      const [code, number] = value.split(' ');
                      handleFieldUpdate(patient.userId, 'phone_code', code.replace('+', ''));
                      handleFieldUpdate(patient.userId, 'phone_number', number);
                    }}
                  />
                  <EditableField
                    value={patient.local_address}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'local_address', value)}
                  />
                  <EditableField
                    value={patient.preferred_language}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'preferred_language', value)}
                    type="select"
                    options={LANGUAGES.map(lang => ({ value: lang.code, label: lang.name }))}
                  />
                  <EditableField
                    value={patient.role_id}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'role_id', value)}
                    type="select"
                    options={[
                      { value: '1', label: '관리자' },
                      { value: '2', label: '운영자' },
                      { value: '3', label: '환자' },
                      { value: '4', label: '가입자' }
                    ]}
                  />
                  <EditableField
                    value={patient.chronic_diseases}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'chronic_diseases', value)}
                  />
                  <EditableField
                    value={patient.medications}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'medications', value)}
                  />
                  <EditableField
                    value={patient.allergies}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'allergies', value)}
                  />
                  <EditableField
                    value={patient.insured_yn}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'insured_yn', value)}
                    type="select"
                    options={[
                      { value: 'Y', label: '가입' },
                      { value: 'N', label: '미가입' }
                    ]}
                  />
                  <EditableField
                    value={patient.coverage_details}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'coverage_details', value)}
                  />
                  <Td>{formatDateTime(patient.created_at)}</Td>
                  <Td>{formatDateTime(patient.last_login)}</Td>
                  <EditableField
                    value={patient.patient_status || 'waiting'}
                    onChange={(value) => handleFieldUpdate(patient.userId, 'patientStatus', value)}
                    type="select"
                    options={patientStatusOptions}
                  />
                  <Td>
                    <ActionButton 
                      className="edit"
                      onClick={() => handleEdit(patient)}
                    >
                      수정
                    </ActionButton>
                    <ActionButton 
                      className="delete"
                      onClick={() => handleDelete(patient.userId)}
                    >
                      삭제
                    </ActionButton>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </ScrollContainer>

      {showForm && (
        <PatientForm
          patient={selectedPatient}
          onSubmit={handleFormSubmit}
          onClose={() => {
            setShowForm(false);
            setSelectedPatient(null);
          }}
        />
      )}
    </Container>
  );
};

export default PatientList; 