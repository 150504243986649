import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1a1a1a;
  margin-bottom: 24px;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #4a4a4a;
`;

const Select = styled.select`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}

  ${props => props.secondary && `
    background: #e9ecef;
    color: #4a4a4a;
    &:hover { background: #dee2e6; }
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 24px;
  border-top: 1px solid #e9ecef;
  position: sticky;
  bottom: 0;
  background: white;
`;

// 진료과 데이터 구조 수정
const DEPARTMENTS = {
  'B1': [
    { id: 18, name: '물리치료실', type: 'support', treatments: ['물리치료', '도수치료', '체외충격파 치료'] },
    { id: 19, name: '도수치료실', type: 'support', treatments: ['도수치료', '자세 교정 상담 및 교육'] },
    { id: 17, name: '치과', type: 'medical', treatments: ['치과 검진', '스케일링', '충치 치료'] },
    { id: 20, name: '약국', type: 'support', treatments: ['처방약 조제', '복약 지도'] }
  ],
  '1F': [
    { id: 1, name: '정형외과', type: 'medical', treatments: ['X-ray 검사', 'MRI 검사', '초음파 검사', '관절 주사', '깁스 치료'] },
    { id: 2, name: '호흡기내과', type: 'medical', treatments: ['폐기능 검사', '흉부 X-ray', '기관지 내시경'] },
    { id: 4, name: '응급실', type: 'medical', treatments: ['응급 처치', '초기 진단', '응급 수술'] },
    { id: 21, name: '영상의학과', type: 'support', treatments: ['X-ray 촬영', 'CT 촬영', 'MRI 촬영'] },
    { id: 22, name: '주사실', type: 'support', treatments: ['주사 치료', '수액 치료'] }
  ],
  '2F': [
    { id: 5, name: '척추외과', type: 'medical', treatments: ['척추 MRI', '신경차단술', '척추 교정'] },
    { id: 6, name: '신경과', type: 'medical', treatments: ['뇌파 검사', '근전도 검사', '인지기능 검사'] },
    { id: 9, name: '안과', type: 'medical', treatments: ['시력 검사', '안저 검사', '녹내장 검사'] },
    { id: 10, name: '소화기내과', type: 'medical', treatments: ['위내시경', '대장내시경', '초음파 검사'] },
    // ... 다른 2층 진료과들 ...
  ],
  '3F': [
    { id: 16, name: '통증클리닉', type: 'medical', treatments: ['통증 주사', '신경차단술', '물리치료'] },
    // ... 다른 3층 진료과들 ...
  ]
};

// 스타일 컴포넌트 추가
const DepartmentSelector = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

const FloorSection = styled.div`
  flex: 1;
`;

const FloorTitle = styled.h4`
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 2px solid #007AFF;
`;

const DepartmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DepartmentButton = styled.button`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${props => props.selected ? '#E3F2FD' : 'white'};
  color: ${props => props.selected ? '#007AFF' : '#1a1a1a'};
  cursor: pointer;
  text-align: left;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.selected ? '#E3F2FD' : '#f8f9fa'};
    border-color: #007AFF;
  }
`;

// 추가 스타일 컴포넌트
const TreatmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const TreatmentButton = styled.button`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${props => props.selected ? '#E3F2FD' : 'white'};
  color: ${props => props.selected ? '#007AFF' : '#1a1a1a'};
  cursor: pointer;
  text-align: left;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.selected ? '#E3F2FD' : '#f8f9fa'};
    border-color: #007AFF;
  }
`;

const TreatmentForm = ({ onClose, onSubmit, initialData }) => {
  const [users, setUsers] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_id: '',
    doctor_id: '',
    department_id: '',
    treatment_date: '',
    treatment_time: '',
    symptoms: '',
    diagnosis: '',
    prescription: '',
    notes: '',
    status: 'waiting',
    ...initialData
  });
  const [appointment, setAppointment] = useState(null);
  const [consultation, setConsultation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(initialData?.department_id || '');
  const [selectedTreatmentType, setSelectedTreatmentType] = useState(initialData?.treatment_name || '');

  useEffect(() => {
    loadInitialData();
    if (initialData?.appointment_id) {
      loadAppointmentInfo(initialData.appointment_id);
    }
    if (initialData?.consultation_id) {
      loadConsultationInfo(initialData.consultation_id);
    }
  }, [initialData]);

  const loadInitialData = async () => {
    try {
      setLoading(true);
      
      // 사용자 목록 로드
      const usersResponse = await makeApiRequest('/api/users');
      if (usersResponse.data.success) {
        setUsers(usersResponse.data.data);
      }

      // 의사 목록 로드
      const doctorsResponse = await makeApiRequest('/api/doctors');
      if (doctorsResponse.data.success) {
        setDoctors(doctorsResponse.data.data);
      }

      // 진료과 목록 로드
      const departmentsResponse = await makeApiRequest('/api/departments');
      if (departmentsResponse.data.success) {
        setDepartments(departmentsResponse.data.data);
      }
    } catch (error) {
      console.error('데이터 로드 실패:', error);
      toast.error('필요한 데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const loadAppointmentInfo = async (appointmentId) => {
    try {
      const response = await makeApiRequest(`/api/appointments/${appointmentId}`, {
        params: {
          include: 'department,doctor,user'
        }
      });
      
      if (response.data.success) {
        const appointmentData = response.data.data;
        setAppointment(appointmentData);
        
        // 예약 정보로 폼 데이터 초기화
        setFormData(prev => ({
          ...prev,
          user_id: appointmentData.user_id,
          department_id: appointmentData.department_id,
          doctor_id: appointmentData.doctor_id,
          treatment_date: appointmentData.appointment_date?.split('T')[0] || '',
          treatment_time: appointmentData.appointment_time?.substring(0, 5) || '',
          status: appointmentData.status === 'scheduled' ? 'waiting' : 'in_progress',
          patient_name: appointmentData.user?.name || prev.patient_name,
          patient_number: appointmentData.user?.hospital_patient_no || prev.patient_number
        }));

        // 진료과에 속한 의사 목록 로드
        if (appointmentData.department_id) {
          const doctorsResponse = await makeApiRequest(`/api/departments/${appointmentData.department_id}/doctors`);
          if (doctorsResponse.data.success) {
            setDoctors(doctorsResponse.data.data);
          }
        }
      }
    } catch (error) {
      console.error('예약 정보 로드 실패:', error);
      toast.error('예약 정보를 불러오는데 실패했습니다.');
    }
  };

  const loadConsultationInfo = async (consultationId) => {
    try {
      const response = await makeApiRequest(`/api/consultations/${consultationId}`);
      if (response.data.success) {
        setConsultation(response.data.data);
        // 상담 정보로 폼 데이터 업데이트
        setFormData(prev => ({
          ...prev,
          symptoms: response.data.data.content || prev.symptoms,
          needs_translator: response.data.data.translator_required
        }));
      }
    } catch (error) {
      console.error('상담 정보 로드 실패:', error);
    }
  };

  const getAppointmentInfo = async (user) => {
    try {
      // 현재 날짜 기준으로 예약 조회
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      const response = await makeApiRequest('/api/appointments', {
        params: {
          userId: user.user_id,
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          status: ['scheduled', 'confirmed'].join(',')
        }
      });

      if (response.data.success && response.data.data.length > 0) {
        // 가장 최근 예약 반환
        const appointments = response.data.data;
        const sortedAppointments = appointments.sort((a, b) => {
          const dateA = new Date(`${a.appointment_date} ${a.appointment_time || '00:00'}`);
          const dateB = new Date(`${b.appointment_date} ${b.appointment_time || '00:00'}`);
          return dateA - dateB;
        });

        return sortedAppointments[0];
      }
      return null;
    } catch (error) {
      console.error('예약 정보 조회 실패:', error);
      return null;
    }
  };

  const handleChange = async (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    // 환자 선택 시 기존 예약 정보 확인
    if (field === 'user_id' && value) {
      const selectedUser = users.find(u => u.user_id === parseInt(value));
      if (selectedUser) {
        // 기존 예약 정보가 있다면 해당 정보로 초기화
        const appointment = await getAppointmentInfo(selectedUser);
        if (appointment) {
          setSelectedDepartment(appointment.department_id);
          setFormData(prev => ({
            ...prev,
            department_id: appointment.department_id,
            doctor_id: appointment.doctor_id,
            treatment_date: appointment.appointment_date,
            treatment_time: appointment.appointment_time
          }));
        }
      }
    }
  };

  const handleAppointmentUpdate = async (appointmentData) => {
    try {
      // 예약 상태 업데이트
      await makeApiRequest(`/api/appointments/${appointmentData.appointment_id}`, {
        method: 'PUT',
        data: {
          department_id: appointmentData.department_id,
          doctor_id: appointmentData.doctor_id,
          appointment_date: appointmentData.appointment_date,
          appointment_time: appointmentData.appointment_time,
          status: 'in_progress',
          process_status: '진료중',
          updated_by: formData.doctor_id
        }
      });
      
      // 환자 상태 업데이트
      await makeApiRequest(`/api/users/${appointmentData.user_id}/status`, {
        method: 'PUT',
        data: { 
          patient_status: 'in_treatment',
          updated_by: formData.doctor_id
        }
      });

      toast.success('예약 정보가 수정되었습니다.');
    } catch (error) {
      console.error('예약 수정 실패:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      
      if (!selectedDepartment || !selectedTreatmentType) {
        toast.error('진료과와 진료 항목을 선택해주세요.');
        return;
      }

      const treatmentData = {
        ...formData,
        department_id: selectedDepartment,
        treatment_name: selectedTreatmentType,
        treatment_type: 'outpatient',
        status: 'in_progress'
      };

      // 예약 정보 업데이트
      if (initialData?.appointment_id) {
        await handleAppointmentUpdate({
          appointment_id: initialData.appointment_id,
          department_id: selectedDepartment,
          doctor_id: formData.doctor_id,
          appointment_date: formData.treatment_date,
          appointment_time: formData.treatment_time
        });
      }

      // 진료 정보 저장
      await onSubmit(treatmentData);
      toast.success('외래 진료가 등록되었습니다.');
      onClose();
    } catch (error) {
      console.error('진료 처리 실패:', error);
      toast.error('진료 처리에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 진료과 선택 핸들러
  const handleDepartmentSelect = (dept) => {
    setSelectedDepartment(dept.id);
    setFormData(prev => ({
      ...prev,
      department_id: dept.id,
      treatment_name: '', // 진료과가 변경되면 진료 항목 초기화
    }));
    setSelectedTreatmentType('');
  };

  // 진료 항목 선택 핸들러
  const handleTreatmentSelect = (treatmentName) => {
    setSelectedTreatmentType(treatmentName);
    setFormData(prev => ({
      ...prev,
      treatment_name: treatmentName
    }));
  };

  // 선택된 진료과의 진료 항목 목록 가져오기
  const getSelectedDepartmentTreatments = () => {
    for (const floor of Object.values(DEPARTMENTS)) {
      const dept = floor.find(d => d.id === selectedDepartment);
      if (dept) {
        return dept.treatments;
      }
    }
    return [];
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  return (
    <Modal>
      <FormContainer>
        <Title>{initialData ? '진료 정보 수정' : '새 진료 등록'}</Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup className="full-width">
            <Label>진료과 선택</Label>
            <DepartmentSelector>
              {Object.entries(DEPARTMENTS).map(([floor, departments]) => (
                <FloorSection key={floor}>
                  <FloorTitle>{floor}</FloorTitle>
                  <DepartmentList>
                    {departments.map(dept => (
                      <DepartmentButton
                        key={dept.id}
                        type="button"
                        selected={selectedDepartment === dept.id}
                        onClick={() => handleDepartmentSelect(dept)}
                      >
                        {dept.name}
                      </DepartmentButton>
                    ))}
                  </DepartmentList>
                </FloorSection>
              ))}
            </DepartmentSelector>
          </FormGroup>

          {selectedDepartment && (
            <FormGroup className="full-width">
              <Label>진료 항목 선택</Label>
              <TreatmentList>
                {getSelectedDepartmentTreatments().map(treatment => (
                  <TreatmentButton
                    key={treatment}
                    type="button"
                    selected={selectedTreatmentType === treatment}
                    onClick={() => handleTreatmentSelect(treatment)}
                  >
                    {treatment}
                  </TreatmentButton>
                ))}
              </TreatmentList>
            </FormGroup>
          )}

          <FormGroup>
            <Label>환자</Label>
            <Select
              value={formData.user_id}
              onChange={(e) => handleChange('user_id', e.target.value)}
            >
              <option value="">환자 선택</option>
              {users.map(user => (
                <option key={user.user_id} value={user.user_id}>
                  {user.name} ({user.hospital_patient_no})
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>진료과</Label>
            <Select
              value={formData.department_id}
              onChange={(e) => handleChange('department_id', e.target.value)}
              disabled={!!appointment} // 예약 정보가 있으면 수정 불가
            >
              <option value="">진료과 선택</option>
              {departments.map(dept => (
                <option key={dept.department_id} value={dept.department_id}>
                  {dept.department_name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>담당의</Label>
            <Select
              value={formData.doctor_id}
              onChange={(e) => handleChange('doctor_id', e.target.value)}
            >
              <option value="">담당의 선택</option>
              {doctors.map(doctor => (
                <option key={doctor.doctor_id} value={doctor.doctor_id}>
                  {doctor.name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>진료 일자</Label>
            <Input
              type="date"
              value={formData.treatment_date}
              onChange={(e) => handleChange('treatment_date', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>진료 시간</Label>
            <Input
              type="time"
              value={formData.treatment_time}
              onChange={(e) => handleChange('treatment_time', e.target.value)}
            />
          </FormGroup>

          <FormGroup className="full-width">
            <Label>증상</Label>
            <Input
              type="text"
              value={formData.symptoms}
              onChange={(e) => handleChange('symptoms', e.target.value)}
              placeholder="환자의 증상을 입력하세요"
            />
          </FormGroup>

          <ButtonGroup>
            <Button type="button" secondary onClick={onClose}>
              취소
            </Button>
            <Button type="submit" primary>
              {initialData ? '수정' : '등록'}
            </Button>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default TreatmentForm; 