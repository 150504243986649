import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 0;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const ModalHeader = styled.div`
  padding: 20px 24px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;

  h2 {
    margin: 0;
    font-size: 20px;
    color: #1a1a1a;
  }
`;

const FormContainer = styled.div`
  padding: 24px;
`;

const FormSection = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  color: #1a1a1a;
  margin: 0 0 16px 0;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #4a4a4a;
  font-size: 14px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-height: 100px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}

  ${props => props.secondary && `
    background: #e9ecef;
    color: #4a4a4a;
    &:hover { background: #dee2e6; }
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 24px;
  border-top: 1px solid #e9ecef;
  position: sticky;
  bottom: 0;
  background: white;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const SearchResults = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 8px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
`;

const SearchResultItem = styled.div`
  padding: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  align-items: center;

  &:hover {
    background: #f5f7f9;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const SearchResultLabel = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const SearchResultValue = styled.div`
  font-size: 14px;
  color: #333;
`;

const PatientInfo = styled.div`
  background: #f8f9fa;
  padding: 12px;
  border-radius: 4px;
  margin-top: 8px;
`;

const ConsultationForm = ({ onClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    patient_id: '',
    doctor_id: '',
    consultation_date: format(new Date(), 'yyyy-MM-dd'),
    consultation_time: '',
    consultation_type: 'online',
    subject: '',
    content: '',
    response: '',
    status: 'pending',
    priority: 'normal',
    translator_required: false,
    preferred_language: '',
    attachments: []
  });

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        consultation_date: format(new Date(initialData.consultation_date), 'yyyy-MM-dd')
      });
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      await onSubmit(formData);
      toast.success(initialData ? '상담 정보가 수정되었습니다.' : '상담이 등록되었습니다.');
      onClose();
    } catch (error) {
      console.error('상담 처리 실패:', error);
      toast.error(error.response?.data?.message || '상담 처리에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (!query || query.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await makeApiRequest('/api/patients/search', {
        params: { query }
      });

      if (response.data.success) {
        setSearchResults(response.data.data);
      } else {
        console.error('검색 실패:', response.data.message);
        setSearchResults([]);
      }
    } catch (error) {
      console.error('환자 검색 실패:', error);
      if (error.response?.status !== 404) {
        toast.error('환자 검색에 실패했습니다.');
      }
      setSearchResults([]);
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchQuery) {
        handleSearch(searchQuery);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  const handlePatientSelect = (patient) => {
    setFormData(prev => ({
      ...prev,
      user_id: patient.user_id,
      patient_name: patient.name,
      patient_number: patient.hospital_patient_no
    }));
    setSelectedPatient({
      user_id: patient.user_id,
      name: patient.name,
      patient_number: patient.hospital_patient_no,
      phone_number: patient.phone_number,
      birth_date: patient.birth_date
    });
    setSearchResults([]);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Modal onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <h2>{initialData ? '상담 정보 수정' : '새 상담 등록'}</h2>
          <Button type="button" secondary onClick={onClose}>✕</Button>
        </ModalHeader>

        <form onSubmit={handleSubmit}>
          <FormContainer>
            <FormSection>
              <SectionTitle>환자 정보</SectionTitle>
              <FormGroup>
                <Label>환자 검색</Label>
                <SearchInput
                  type="text"
                  placeholder="이름, 연락처, 생년월일로 검색 (2글자 이상)"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleSearchKeyPress}
                />

                {searchResults.length > 0 && (
                  <SearchResults>
                    {searchResults.map(patient => (
                      <SearchResultItem
                        key={patient.user_id}
                        onClick={() => handlePatientSelect(patient)}
                      >
                        <div>
                          <SearchResultLabel>이름</SearchResultLabel>
                          <SearchResultValue>
                            {patient.name}
                            {patient.english_name && ` (${patient.english_name})`}
                          </SearchResultValue>
                        </div>
                        <div>
                          <SearchResultLabel>환자번호</SearchResultLabel>
                          <SearchResultValue>{patient.hospital_patient_no || '-'}</SearchResultValue>
                        </div>
                        <div>
                          <SearchResultLabel>연락처</SearchResultLabel>
                          <SearchResultValue>{patient.phone_number}</SearchResultValue>
                        </div>
                        <div>
                          <SearchResultLabel>생년월일</SearchResultLabel>
                          <SearchResultValue>
                            {patient.birth_date ? format(new Date(patient.birth_date), 'yyyy-MM-dd') : '-'}
                          </SearchResultValue>
                        </div>
                      </SearchResultItem>
                    ))}
                  </SearchResults>
                )}

                {selectedPatient && (
                  <PatientInfo>
                    <div>이름: {selectedPatient.name}</div>
                    <div>환자번호: {selectedPatient.patient_number}</div>
                    <div>연락처: {selectedPatient.phone_number}</div>
                    {selectedPatient.birth_date && (
                      <div>생년월일: {format(new Date(selectedPatient.birth_date), 'yyyy-MM-dd')}</div>
                    )}
                  </PatientInfo>
                )}
              </FormGroup>
            </FormSection>

            <FormSection>
              <SectionTitle>기본 정보</SectionTitle>
              <FormGroup>
                <Label>상담 유형</Label>
                <Select
                  value={formData.consultation_type}
                  onChange={e => setFormData(prev => ({ ...prev, consultation_type: e.target.value }))}
                  required
                >
                  <option value="online">온라인 상담</option>
                  <option value="phone">전화 상담</option>
                  <option value="video">화상 상담</option>
                  <option value="in_person">대면 상담</option>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>상담 일자</Label>
                <Input
                  type="date"
                  value={formData.consultation_date}
                  onChange={e => setFormData(prev => ({ ...prev, consultation_date: e.target.value }))}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label>상담 시간</Label>
                <Input
                  type="time"
                  value={formData.consultation_time}
                  onChange={e => setFormData(prev => ({ ...prev, consultation_time: e.target.value }))}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label>우선순위</Label>
                <Select
                  value={formData.priority}
                  onChange={e => setFormData(prev => ({ ...prev, priority: e.target.value }))}
                >
                  <option value="low">낮음</option>
                  <option value="normal">보통</option>
                  <option value="high">높음</option>
                  <option value="urgent">긴급</option>
                </Select>
              </FormGroup>
            </FormSection>

            <FormSection>
              <SectionTitle>상담 내용</SectionTitle>
              <FormGroup>
                <Label>제목</Label>
                <Input
                  type="text"
                  value={formData.subject}
                  onChange={e => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                  placeholder="상담 제목을 입력하세요"
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label>상담 내용</Label>
                <TextArea
                  value={formData.content}
                  onChange={e => setFormData(prev => ({ ...prev, content: e.target.value }))}
                  placeholder="상담 내용을 입력하세요"
                  required
                />
              </FormGroup>

              {initialData && (
                <FormGroup>
                  <Label>답변</Label>
                  <TextArea
                    value={formData.response}
                    onChange={e => setFormData(prev => ({ ...prev, response: e.target.value }))}
                    placeholder="답변을 입력하세요"
                  />
                </FormGroup>
              )}
            </FormSection>

            <FormSection>
              <SectionTitle>추가 정보</SectionTitle>
              <FormGroup>
                <Label>
                  <input
                    type="checkbox"
                    checked={formData.translator_required}
                    onChange={e => setFormData(prev => ({ 
                      ...prev, 
                      translator_required: e.target.checked 
                    }))}
                  />
                  {' '}통역이 필요합니다
                </Label>
              </FormGroup>

              {formData.translator_required && (
                <FormGroup>
                  <Label>선호 언어</Label>
                  <Select
                    value={formData.preferred_language}
                    onChange={e => setFormData(prev => ({ 
                      ...prev, 
                      preferred_language: e.target.value 
                    }))}
                    required
                  >
                    <option value="">언어를 선택하세요</option>
                    <option value="en">영어</option>
                    <option value="zh">중국어</option>
                    <option value="ja">일본어</option>
                    <option value="vi">베트남어</option>
                    <option value="other">기타</option>
                  </Select>
                </FormGroup>
              )}
            </FormSection>
          </FormContainer>

          <ButtonGroup>
            <Button type="button" secondary onClick={onClose}>
              취소
            </Button>
            <Button type="submit" primary disabled={loading}>
              {initialData ? '수정하기' : '등록하기'}
            </Button>
          </ButtonGroup>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ConsultationForm; 