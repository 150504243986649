import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';
import DepartmentForm from './DepartmentForm';

const Container = styled.div`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 6px;
  
  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
  
  ${props => props.secondary && `
    background: #e9ecef;
    color: #495057;
    &:hover { background: #dee2e6; }
  `}

  ${props => props.danger && `
    background: #dc3545;
    color: white;
    &:hover { background: #c82333; }
  `}
`;

const ContentLayout = styled.div`
  height: calc(100vh - 180px);
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  padding: 24px;
`;

const FloorSection = styled.div`
  margin-bottom: 32px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FloorHeader = styled.div`
  padding: 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 18px;
    color: #1a1a1a;
    font-weight: 600;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 16px;
  text-align: left;
  font-weight: 600;
  color: #495057;
  border-bottom: 2px solid #e9ecef;
  background: white;
`;

const Td = styled.td`
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  color: #212529;
  vertical-align: middle;

  &:last-child {
    width: 180px;
  }
`;

const DepartmentBadge = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  background: ${props => props.type === '진료실' ? '#e3f2fd' : '#fff3e0'};
  color: ${props => props.type === '진료실' ? '#1976d2' : '#f57c00'};
`;

const EmptyState = styled.div`
  padding: 32px;
  text-align: center;
  color: #666;

  p {
    margin: 0 0 16px 0;
  }
`;

const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  // 층수 배열을 내림차순으로 정렬 (5층부터 B1층까지)
  const floors = ['5', '4', '3', '2', '1', 'B1'];

  useEffect(() => {
    loadDepartments();
  }, []);

  const loadDepartments = async () => {
    try {
      setLoading(true);
      const response = await makeApiRequest('/api/departments');
      if (response.data.success) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error('진료과 목록 로드 실패:', error);
      toast.error('진료과 목록을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (department) => {
    setSelectedDepartment(department);
    setShowForm(true);
  };

  const handleSubmit = async (departmentData) => {
    try {
      if (selectedDepartment?.department_id) {
        // 수정
        const response = await makeApiRequest(`/api/departments/${selectedDepartment.department_id}`, {
          method: 'PUT',
          data: departmentData
        });
        
        if (response.data.success) {
          toast.success('진료과 정보가 수정되었습니다.');
          await loadDepartments();
          setShowForm(false);
          setSelectedDepartment(null);
        }
      } else {
        // 추가
        const response = await makeApiRequest('/api/departments', {
          method: 'POST',
          data: departmentData
        });
        
        if (response.data.success) {
          toast.success('새 진료과가 추가되었습니다.');
          await loadDepartments();
          setShowForm(false);
          setSelectedDepartment(null);
        }
      }
    } catch (error) {
      console.error('진료과 저장 실패:', error);
      toast.error(error.response?.data?.message || '진료과 정보 저장에 실패했습니다.');
    }
  };

  const handleDelete = async (department) => {
    if (window.confirm('정말 이 진료과를 삭제하시겠습니까?')) {
      try {
        const response = await makeApiRequest(`/api/departments/${department.department_id}`, {
          method: 'DELETE'
        });
        
        if (response.data.success) {
          toast.success('진료과가 삭제되었습니다.');
          await loadDepartments();
        }
      } catch (error) {
        console.error('진료과 삭제 실패:', error);
        toast.error(error.response?.data?.message || '진료과 삭제에 실패했습니다.');
      }
    }
  };

  const getDepartmentsByFloor = (floor) => {
    return departments.filter(dept => dept.location === floor);
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  return (
    <Container>
      <Header>
        <Title>진료실 관리</Title>
        <ActionButton primary onClick={() => setShowForm(true)}>
          <i className="fas fa-plus"></i>
          새 진료과 추가
        </ActionButton>
      </Header>

      <ContentLayout>
        {floors.map(floor => {
          const floorDepartments = getDepartmentsByFloor(floor);
          const floorDisplay = floor === 'B1' ? '지하 1층' : `${floor}층`;
          
          return (
            <FloorSection key={floor}>
              <FloorHeader>
                <h3>{floorDisplay}</h3>
                <ActionButton 
                  secondary
                  onClick={() => {
                    setSelectedDepartment({ location: floor, department_type: '진료실' });
                    setShowForm(true);
                  }}
                >
                  <i className="fas fa-plus"></i>
                  진료과 추가
                </ActionButton>
              </FloorHeader>

              {floorDepartments.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <Th>진료과명</Th>
                      <Th>유형</Th>
                      <Th>설명</Th>
                      <Th>작업</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {floorDepartments.map(dept => (
                      <tr key={dept.department_id}>
                        <Td>{dept.department_name}</Td>
                        <Td>
                          <DepartmentBadge type={dept.department_type}>
                            {dept.department_type}
                          </DepartmentBadge>
                        </Td>
                        <Td>{dept.description || '-'}</Td>
                        <Td>
                          <ButtonGroup>
                            <ActionButton 
                              secondary 
                              onClick={() => handleEdit(dept)}
                            >
                              <i className="fas fa-edit"></i>
                              수정
                            </ActionButton>
                            <ActionButton 
                              danger
                              onClick={() => handleDelete(dept)}
                            >
                              <i className="fas fa-trash"></i>
                              삭제
                            </ActionButton>
                          </ButtonGroup>
                        </Td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <EmptyState>
                  <p>이 층에 등록된 진료과가 없습니다.</p>
                  <ActionButton 
                    secondary
                    onClick={() => {
                      setSelectedDepartment({ location: floor });
                      setShowForm(true);
                    }}
                  >
                    <i className="fas fa-plus"></i>
                    진료과 추가하기
                  </ActionButton>
                </EmptyState>
              )}
            </FloorSection>
          );
        })}
      </ContentLayout>

      {showForm && (
        <DepartmentForm
          onClose={() => {
            setShowForm(false);
            setSelectedDepartment(null);
          }}
          onSubmit={handleSubmit}
          initialData={selectedDepartment}
          floors={floors}
          isEdit={!!selectedDepartment?.department_id}
        />
      )}
    </Container>
  );
};

export default DepartmentList; 