import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  overflow: hidden;
`;

const FormContainer = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Title = styled.h3`
  margin-bottom: 24px;
  color: #1d1d1f;
  position: sticky;
  top: 0;
  background: white;
  padding: 12px 0;
  z-index: 2;
  border-bottom: 1px solid #e1e1e1;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding-bottom: 24px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  &.full-width {
    grid-column: 1 / -1;
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: #86868b;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  font-size: 14px;
  
  &:focus {
    border-color: #007AFF;
    outline: none;
  }
`;

const Select = styled.select`
  padding: 8px 12px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  font-size: 14px;
  
  &:focus {
    border-color: #007AFF;
    outline: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
  position: sticky;
  bottom: 0;
  background: white;
  padding: 12px 0;
  border-top: 1px solid #e1e1e1;
  z-index: 2;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  
  &.primary {
    background: #007AFF;
    color: white;
  }
  
  &.secondary {
    background: #f5f5f7;
    color: #1d1d1f;
  }
`;

// 언어 옵션 상수 추가
const LANGUAGES = [
  { code: 'ko', name: '한국어' },
  { code: 'en', name: '영어' },
  { code: 'es', name: '스페인어' },
  { code: 'fr', name: '프랑스어' },
  { code: 'de', name: '독일어' },
  { code: 'it', name: '이탈리아어' },
  { code: 'pt', name: '포르투갈어' },
  { code: 'zh', name: '중국어' },
  { code: 'ja', name: '일본어' },
  { code: 'ar', name: '아랍어' },
  { code: 'ru', name: '러시아어' },
  { code: 'hi', name: '힌디어' },
  { code: 'tr', name: '터키어' },
  { code: 'vi', name: '베트남어' }
];

// 국가 전화번호 코드 상수 추가
const COUNTRY_CODES = [
  { code: '+82', country: '대한민국' },
  { code: '+1', country: '미국/캐나다' },
  { code: '+81', country: '일본' },
  { code: '+86', country: '중국' },
  { code: '+84', country: '베트남' },
  { code: '+91', country: '인도' },
  { code: '+49', country: '독일' },
  { code: '+33', country: '프랑스' },
  { code: '+34', country: '스페인' },
  { code: '+39', country: '이탈리아' },
  { code: '+7', country: '러시아' },
  { code: '+44', country: '영국' },
  { code: '+55', country: '브라질' },
  { code: '+52', country: '멕시코' },
  { code: '+966', country: '사우디아라비아' },
  { code: '+971', country: '아랍에미리트' },
  { code: '+61', country: '호주' }
];

// 역할 상수 추가
const ROLES = [
  { id: 1, name: '관리자' },
  { id: 2, name: '운영자' },
  { id: 3, name: '환자' },
  { id: 4, name: '가입자' }
];

// 상태 옵션 상수 추가
const STATUS_OPTIONS = [
  { value: 'active', label: '활성' },
  { value: 'inactive', label: '비활성' },
  { value: 'pending', label: '대기중' }
];

// 환자 상태 매핑 객체 추가
const patientStatusMap = {
  'waiting': '대기',
  'reserved': '예약',
  'outpatient': '외래',
  'hospitalized': '입원',
  'discharged': '귀가'
};

// SelectBox 컴포넌트에서 사용할 옵션 배열
const patientStatusOptions = [
  { value: 'waiting', label: '대기' },
  { value: 'reserved', label: '예약' },
  { value: 'outpatient', label: '외래' },
  { value: 'hospitalized', label: '입원' },
  { value: 'discharged', label: '귀가' }
];

const PatientForm = ({ patient, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    english_name: '',
    local_name: '',
    email: '',
    password: '',
    phone_code: '+82',
    phone_number: '',
    hospital_patient_no: '',
    patient_number: '',
    nationality: 'KR',
    birth_date: '',
    gender: '',
    local_address: '',
    preferred_language: 'ko',
    role_id: '3',
    chronic_diseases: '',
    medications: '',
    allergies: '',
    insured_yn: 'N',
    coverage_details: '',
    status: 'active',
    patient_status: 'waiting',
    ...patient
  });

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // 필수 필드 검증
    const requiredFields = ['name', 'email', 'phone_number'];
    if (!patient) {
      requiredFields.push('password'); // 새 환자 등록시에만 비밀번호 필수
    }
    
    const missingFields = requiredFields.filter(field => !formData[field]);
    
    if (missingFields.length > 0) {
      toast.error(`다음 필드를 입력해주세요: ${missingFields.join(', ')}`);
      return;
    }

    onSubmit(formData);
  };

  return (
    <Modal>
      <FormContainer>
        <Title>{patient ? '환자 정보 수정' : '새 환자 등록'}</Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>이름 *</Label>
            <Input
              type="text"
              value={formData.name}
              onChange={(e) => handleChange('name', e.target.value)}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>영문 이름</Label>
            <Input
              type="text"
              value={formData.english_name}
              onChange={(e) => handleChange('english_name', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>현지어 이름</Label>
            <Input
              type="text"
              value={formData.local_name}
              onChange={(e) => handleChange('local_name', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>이메일 *</Label>
            <Input
              type="email"
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
              required
            />
          </FormGroup>

          {!patient && (
            <FormGroup>
              <Label>비밀번호 *</Label>
              <Input
                type="password"
                value={formData.password}
                onChange={(e) => handleChange('password', e.target.value)}
                required
              />
            </FormGroup>
          )}

          <FormGroup>
            <Label>성별</Label>
            <Select
              value={formData.gender}
              onChange={(e) => handleChange('gender', e.target.value)}
            >
              <option value="">선택하세요</option>
              <option value="M">남성</option>
              <option value="F">여성</option>
              <option value="O">기타</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>생년월일</Label>
            <Input
              type="date"
              value={formData.birth_date}
              onChange={(e) => handleChange('birth_date', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>국적</Label>
            <Input
              type="text"
              value={formData.nationality}
              onChange={(e) => handleChange('nationality', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>여권번호</Label>
            <Input
              type="text"
              value={formData.passport_number}
              onChange={(e) => handleChange('passport_number', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>환자번호</Label>
            <Input
              type="text"
              value={formData.patient_number}
              onChange={(e) => handleChange('patient_number', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>병원환자번호</Label>
            <Input
              type="text"
              value={formData.hospital_patient_no}
              onChange={(e) => handleChange('hospital_patient_no', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>전화번호 *</Label>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Select
                value={formData.phone_code}
                onChange={(e) => handleChange('phone_code', e.target.value)}
                style={{ width: '120px' }}
              >
                {COUNTRY_CODES.map(country => (
                  <option key={country.code} value={country.code}>
                    {country.code}
                  </option>
                ))}
              </Select>
              <Input
                type="tel"
                value={formData.phone_number}
                onChange={(e) => handleChange('phone_number', e.target.value)}
                required
                style={{ flex: 1 }}
              />
            </div>
          </FormGroup>

          <FormGroup className="full-width">
            <Label>국내 주소</Label>
            <Input
              type="text"
              value={formData.local_address}
              onChange={(e) => handleChange('local_address', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>선호 언어</Label>
            <Select
              value={formData.preferred_language}
              onChange={(e) => handleChange('preferred_language', e.target.value)}
            >
              {LANGUAGES.map(lang => (
                <option key={lang.code} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>구분</Label>
            <Select
              value={formData.role_id}
              onChange={(e) => handleChange('role_id', e.target.value)}
            >
              {ROLES.map(role => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup className="full-width">
            <Label>기저질환</Label>
            <Input
              type="text"
              value={formData.chronic_diseases}
              onChange={(e) => handleChange('chronic_diseases', e.target.value)}
            />
          </FormGroup>

          <FormGroup className="full-width">
            <Label>복용약물</Label>
            <Input
              type="text"
              value={formData.medications}
              onChange={(e) => handleChange('medications', e.target.value)}
            />
          </FormGroup>

          <FormGroup className="full-width">
            <Label>알레르기</Label>
            <Input
              type="text"
              value={formData.allergies}
              onChange={(e) => handleChange('allergies', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>보험가입</Label>
            <Select
              value={formData.insured_yn}
              onChange={(e) => handleChange('insured_yn', e.target.value)}
            >
              <option value="Y">가입</option>
              <option value="N">미가입</option>
            </Select>
          </FormGroup>

          <FormGroup className="full-width">
            <Label>보험상세정보</Label>
            <Input
              type="text"
              value={formData.coverage_details}
              onChange={(e) => handleChange('coverage_details', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label>상태</Label>
            <Select
              value={formData.status}
              onChange={(e) => handleChange('status', e.target.value)}
            >
              {STATUS_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>환자 상태</Label>
            <Select
              value={patientStatusOptions.find(option => 
                option.value === (formData.patient_status || 'waiting')
              )}
              onChange={(selectedOption) => handleChange('patient_status', selectedOption.value)}
              options={patientStatusOptions}
              isSearchable={false}
              formatOptionLabel={option => patientStatusMap[option.value]}
            />
          </FormGroup>

          <ButtonGroup>
            <Button type="button" className="secondary" onClick={onClose}>
              취소
            </Button>
            <Button type="submit" className="primary">
              {patient ? '수정' : '등록'}
            </Button>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default PatientForm; 