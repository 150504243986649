import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';

const Container = styled.div`
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const BackButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #f8f9fa;
  color: #495057;
  cursor: pointer;
  
  &:hover {
    background: #e9ecef;
  }
`;

const PatientInfo = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
`;

const FlowStep = styled.div`
  margin-bottom: 32px;
`;

const StepTitle = styled.h3`
  font-size: 18px;
  color: #1a1a1a;
  margin-bottom: 16px;
`;

const DepartmentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
`;

const DepartmentCard = styled.button`
  padding: 16px;
  border: 1px solid ${props => props.selected ? '#007AFF' : '#dee2e6'};
  border-radius: 8px;
  background: ${props => props.selected ? '#E3F2FD' : 'white'};
  cursor: pointer;
  text-align: left;
  transition: all 0.2s;

  &:hover {
    border-color: #007AFF;
    background: ${props => props.selected ? '#E3F2FD' : '#f8f9fa'};
  }
`;

const TreatmentList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
`;

const TreatmentCard = styled(DepartmentCard)``;

const ConfirmButton = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  background: #007AFF;
  color: white;
  font-size: 16px;
  cursor: pointer;
  
  &:hover {
    background: #0056b3;
  }
  
  &:disabled {
    background: #e9ecef;
    cursor: not-allowed;
  }
`;

const PatientTreatmentFlow = ({ patient, onBack, onComplete }) => {
  const [loading, setLoading] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [existingAppointment, setExistingAppointment] = useState(null);

  useEffect(() => {
    loadInitialData();
  }, [patient]);

  const loadInitialData = async () => {
    try {
      setLoading(true);
      
      // 기존 예약 정보 조회
      const appointmentResponse = await makeApiRequest('/api/appointments', {
        params: {
          userId: patient.user_id,
          status: 'scheduled,confirmed'
        }
      });

      if (appointmentResponse.data.success && appointmentResponse.data.data.length > 0) {
        const latestAppointment = appointmentResponse.data.data[0];
        setExistingAppointment(latestAppointment);
        setSelectedDepartment(latestAppointment.department_id);
      }

      // 진료과 목록 조회
      const deptResponse = await makeApiRequest('/api/departments');
      if (deptResponse.data.success) {
        setDepartments(deptResponse.data.data);
      }

      // 전체 진료 항목 조회
      const treatmentsResponse = await makeApiRequest('/api/treatments/types');
      if (treatmentsResponse.data.success) {
        setTreatments(treatmentsResponse.data.data);
      }
    } catch (error) {
      console.error('데이터 로드 실패:', error);
      toast.error('데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 선택된 진료과의 진료 항목 필터링
  const getSelectedDepartmentTreatments = () => {
    if (!selectedDepartment) return [];
    return treatments.filter(treatment => treatment.department_id === selectedDepartment);
  };

  const handleDepartmentSelect = (dept) => {
    setSelectedDepartment(dept.department_id);
    setSelectedTreatment(null);
  };

  const handleTreatmentSelect = (treatment) => {
    setSelectedTreatment(treatment.treatment_name);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      const treatmentData = {
        user_id: patient.user_id,
        department_id: selectedDepartment,
        treatment_name: selectedTreatment,
        treatment_type: 'outpatient',
        status: 'in_progress',
        appointment_id: existingAppointment?.appointment_id
      };

      const response = await makeApiRequest('/api/treatments', {
        method: 'POST',
        data: treatmentData
      });

      if (response.data.success) {
        toast.success('진료가 등록되었습니다.');
        onComplete();
      }
    } catch (error) {
      console.error('진료 등록 실패:', error);
      toast.error('진료 등록에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  const selectedDepartmentTreatments = getSelectedDepartmentTreatments();

  return (
    <Container>
      <Header>
        <BackButton onClick={onBack}>← 돌아가기</BackButton>
      </Header>

      <PatientInfo>
        <h3>{patient.name}</h3>
        <p>환자번호: {patient.hospital_patient_no}</p>
        {existingAppointment && (
          <p>예약 정보: {existingAppointment.department_name} ({existingAppointment.formatted_date})</p>
        )}
      </PatientInfo>

      <FlowStep>
        <StepTitle>1. 진료과 선택</StepTitle>
        <DepartmentGrid>
          {departments.map(dept => (
            <DepartmentCard
              key={dept.department_id}
              selected={selectedDepartment === dept.department_id}
              onClick={() => handleDepartmentSelect(dept)}
            >
              <h4>{dept.department_name}</h4>
              <p>{dept.location}</p>
            </DepartmentCard>
          ))}
        </DepartmentGrid>
      </FlowStep>

      {selectedDepartment && (
        <FlowStep>
          <StepTitle>2. 진료 항목 선택</StepTitle>
          <TreatmentList>
            {selectedDepartmentTreatments.map(treatment => (
              <TreatmentCard
                key={treatment.treatment_id}
                selected={selectedTreatment === treatment.treatment_name}
                onClick={() => handleTreatmentSelect(treatment)}
              >
                <h4>{treatment.treatment_name}</h4>
                <p>{treatment.description}</p>
              </TreatmentCard>
            ))}
          </TreatmentList>
        </FlowStep>
      )}

      <ConfirmButton
        disabled={!selectedDepartment || !selectedTreatment}
        onClick={handleConfirm}
      >
        진료 등록 완료
      </ConfirmButton>
    </Container>
  );
};

export default PatientTreatmentFlow; 