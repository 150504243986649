import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';
import ConsultationForm from './ConsultationForm';

const Container = styled.div`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`;

const Th = styled.th`
  padding: 12px;
  background: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  text-align: left;
  font-weight: 600;
  color: #495057;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  
  ${props => {
    switch (props.status) {
      case 'completed':
        return 'background: #E3F2FD; color: #1976D2; border: 1px solid #1976D2;';
      case 'in_progress':
        return 'background: #FFF3E0; color: #E65100; border: 1px solid #E65100;';
      case 'pending':
        return 'background: #F5F5F5; color: #616161; border: 1px solid #616161;';
      default:
        return 'background: #FFEBEE; color: #C62828; border: 1px solid #C62828;';
    }
  }}
`;

const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const ConsultationContent = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ConsultationList = () => {
  const [consultations, setConsultations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedConsultation, setSelectedConsultation] = useState(null);

  useEffect(() => {
    loadConsultations();
  }, []);

  const loadConsultations = async () => {
    try {
      setLoading(true);
      const response = await makeApiRequest('/api/consultations');
      if (response.data.success) {
        setConsultations(response.data.data);
      }
    } catch (error) {
      console.error('상담 목록 로드 실패:', error);
      toast.error('상담 목록을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleConsultationSubmit = async (formData) => {
    try {
      if (selectedConsultation) {
        await makeApiRequest(`/api/consultations/${selectedConsultation.consultation_id}`, {
          method: 'PUT',
          data: formData
        });
      } else {
        await makeApiRequest('/api/consultations', {
          method: 'POST',
          data: formData
        });
      }
      loadConsultations();
    } catch (error) {
      console.error('상담 처리 실패:', error);
      throw error;
    }
  };

  const mockConsultations = [
    {
      consultation_id: 1,
      patient_name: '알렉산드라',
      patient_number: 'P2023001',
      consultation_date: '2024-01-29',
      consultation_time: '10:05',
      consultation_type: 'online',
      subject: '내과치료를 받고싶어요.',
      content: '내과치료를 받고싶은데요.',
      status: 'pending',
      priority: 'normal',
      translator_required: true,
      preferred_language: 'ru',
      response: ''
    },
    {
      consultation_id: 2,
      patient_name: '알렉산드라',
      consultation_date: '2024-01-28',
      consultation_time: '17:30',
      consultation_type: 'online',
      subject: 'Спасибо',
      content: 'Спасибо',
      status: 'completed',
      priority: 'low',
      translator_required: true,
      preferred_language: 'ru',
      response: '감사합니다.'
    },
    {
      consultation_id: 3,
      patient_name: '알렉산드라',
      consultation_date: '2024-01-28',
      consultation_time: '17:30',
      consultation_type: 'online',
      subject: 'Спасибо',
      content: 'Спасибо',
      status: 'completed',
      priority: 'low',
      translator_required: true,
      preferred_language: 'ru',
      response: '감사합니다.'
    }
  ];

  const getStatusText = (status) => {
    switch (status) {
      case 'completed': return '답변완료';
      case 'in_progress': return '답변중';
      case 'pending': return '답변대기';
      default: return '취소';
    }
  };

  const getConsultationTypeText = (type) => {
    switch (type) {
      case 'online': return '온라인 상담';
      case 'phone': return '전화 상담';
      case 'video': return '화상 상담';
      case 'in_person': return '대면 상담';
      default: return '기타';
    }
  };

  const getPriorityText = (priority) => {
    switch (priority) {
      case 'urgent': return '긴급';
      case 'high': return '높음';
      case 'normal': return '보통';
      case 'low': return '낮음';
      default: return '보통';
    }
  };

  const getLanguageText = (code) => {
    switch (code) {
      case 'en': return '영어';
      case 'zh': return '중국어';
      case 'ja': return '일본어';
      case 'vi': return '베트남어';
      case 'ru': return '러시아어';
      default: return '기타';
    }
  };

  return (
    <Container>
      <Header>
        <Title>1:1 상담</Title>
        <ActionButton 
          primary 
          onClick={() => {
            setSelectedConsultation(null);
            setShowForm(true);
          }}
        >
          새 상담 등록
        </ActionButton>
      </Header>

      {loading ? (
        <div>로딩 중...</div>
      ) : (
        <Table>
          <thead>
            <tr>
              <Th>상담 일시</Th>
              <Th>환자명</Th>
              <Th>상담 유형</Th>
              <Th>제목</Th>
              <Th>내용</Th>
              <Th>상태</Th>
              <Th>우선순위</Th>
              <Th>통역(언어)</Th>
              <Th>작업</Th>
            </tr>
          </thead>
          <tbody>
            {mockConsultations.map(consultation => (
              <tr key={consultation.consultation_id}>
                <Td>{`${consultation.consultation_date} ${consultation.consultation_time}`}</Td>
                <Td>{consultation.patient_name}</Td>
                <Td>{getConsultationTypeText(consultation.consultation_type)}</Td>
                <Td>
                  <ConsultationContent>{consultation.subject}</ConsultationContent>
                </Td>
                <Td>
                  <ConsultationContent>{consultation.content}</ConsultationContent>
                </Td>
                <Td>
                  <StatusBadge status={consultation.status}>
                    {getStatusText(consultation.status)}
                  </StatusBadge>
                </Td>
                <Td>{getPriorityText(consultation.priority)}</Td>
                <Td>
                  {consultation.translator_required ? 
                    `필요 (${getLanguageText(consultation.preferred_language)})` : 
                    '불필요'}
                </Td>
                <Td>
                  <ActionButtonGroup>
                    <ActionButton
                      onClick={() => {
                        setSelectedConsultation(consultation);
                        setShowForm(true);
                      }}
                    >
                      상세/답변
                    </ActionButton>
                  </ActionButtonGroup>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {showForm && (
        <ConsultationForm
          onClose={() => {
            setShowForm(false);
            setSelectedConsultation(null);
          }}
          onSubmit={handleConsultationSubmit}
          initialData={selectedConsultation}
        />
      )}
    </Container>
  );
};

export default ConsultationList; 