import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1a1a1a;
  margin-bottom: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #4a4a4a;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Select = styled.select`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  
  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
  
  ${props => props.secondary && `
    background: #e9ecef;
    color: #495057;
    &:hover { background: #dee2e6; }
  `}
`;

const DepartmentForm = ({ onClose, onSubmit, initialData, floors, isEdit }) => {
  const [formData, setFormData] = useState({
    department_name: '',
    department_type: '진료실',
    location: '',
    description: ''
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        department_name: initialData.department_name || '',
        department_type: initialData.department_type || '진료실',
        location: initialData.location || '',
        description: initialData.description || ''
      });
    }
  }, [initialData]);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Modal>
      <FormContainer>
        <Title>{isEdit ? '진료과 수정' : '새 진료과 추가'}</Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>진료과명</Label>
            <Input
              type="text"
              value={formData.department_name}
              onChange={(e) => handleChange('department_name', e.target.value)}
              required
              placeholder="예: 내과, 외과, 소아과 등"
            />
          </FormGroup>

          <FormGroup>
            <Label>유형</Label>
            <Select
              value={formData.department_type}
              onChange={(e) => handleChange('department_type', e.target.value)}
              required
            >
              <option value="진료실">진료실</option>
              <option value="진료지원실">진료지원실</option>
              <option value="편의시설">편의시설</option>
              <option value="행정지원">행정지원</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>위치</Label>
            <Select
              value={formData.location}
              onChange={(e) => handleChange('location', e.target.value)}
              required
            >
              <option value="">위치 선택</option>
              {floors.map(floor => (
                <option key={floor} value={floor}>
                  {floor}층
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>설명</Label>
            <Input
              type="text"
              value={formData.description || ''}
              onChange={(e) => handleChange('description', e.target.value)}
              placeholder="진료과에 대한 간단한 설명을 입력하세요"
            />
          </FormGroup>

          <ButtonGroup>
            <Button type="button" secondary onClick={onClose}>
              취소
            </Button>
            <Button type="submit" primary>
              {isEdit ? '수정' : '추가'}
            </Button>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default DepartmentForm; 